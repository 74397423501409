import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p2 from 'assests/Photos/ClassAssembly/2023/4B/1.jpg';
import p1 from 'assests/Photos/ClassAssembly/2023/4B/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/4B/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/4B/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly4B2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 4,
    },
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 2,
    },
    {
        src: p2,
        source:p2,
        rows: 1,
        cols: 2,
      }, 
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 4,
    },
     
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘Quit India Day’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4B   Date: 7 AUGUST 2023 & 8 AUGUST 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        “Our resolve to attain freedom was unbreakable and Quit India Movement stands as a testament to our determination.”– Sarojini Naidu
        <br></br>
        The students of Class 4B presented their assembly on the topic, ‘Quit India Day’ on Monday, 7 August 2023 and Tuesday, 8 August 2023 to mark the celebration of ‘Quit India Day’ that is being observed on  8 August every year.  
        <br></br>
        The assembly began by briefly introducing the importance of the Quit India Movement, a crucial episode in India's fight for independence from the British colonial rule. This was followed by an inspiring speech that delved into the underlying motivations and principles that drove the Quit India Movement. The assembly also highlighted the significant roles of the notable leaders such as Mahatma Gandhi, Pandit Jawaharlal Nehru, Sardar Vallabhbhai Patel and others in uniting the nation towards the pursuit of freedom.
        <br></br>
        Adding an artistic touch to the assembly, students from Class 4B presented a mesmerising dance performance that depicted the emotions, struggles and aspirations of the people who participated in the Quit India Movement.
        <br></br>
        The assembly concluded with a reflection on the sacrifices made by our forefathers that encouraged all students to learn from the lessons of history and cherish the freedom we enjoy today.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Ask not what your country can do for you, ask what you can do for your country”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={4}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4B2023;